import React, { useEffect, useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: theme.spacing(64),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const LazyLoad = (props) => {
  const moreRef = React.useRef(null);
  const { children, ssrRender = false } = props;
  const classes = useStyles();

  const isLazyLoadEnabled = () => {
    if (ssrRender) return true;
    return !(process.browser && 'IntersectionObserver' in window);
  };

  const [showChildren, setShowChildren] = useState(isLazyLoadEnabled());
  const scrollCallBack = (entries) => {
    if (entries[0].intersectionRatio > 0 || entries[0].isIntersecting) {
      setShowChildren(true);
    }
  };

  useEffect(() => {
    if (process.browser && 'IntersectionObserver' in window) {
      const scroll = new IntersectionObserver(scrollCallBack, {
        rootMargin: '0px',
        threshold: 0.8,
      });
      moreRef.current && scroll.observe(moreRef.current);
    }
    return () => {
      if (process.browser && !ssrRender && 'IntersectionObserver' in window) {
        moreRef.current && window.scroll.unobserve(moreRef.current);
      }
    };
  }, [showChildren]);

  if (!showChildren) {
    return (
      <Container ref={moreRef} className={classes.container}>
        {/* <CircularProgress /> */}
        loading...
      </Container>
    );
  }
  return children;
};
export default LazyLoad;
