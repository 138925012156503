import { useLayoutSnackbar, useLayoutError } from 'components/shared/Layout';
import getConfig from 'next/config';
import { Notifier } from '@airbrake/browser';
import { useTranslation, Router as router } from 'config/i18n';

const notifyAirbrake = (error) => {
  const {
    publicRuntimeConfig: { envVars },
  } = getConfig();
  if (envVars.AIRBRAKE_PROJECT_ID && envVars.AIRBRAKE_PROJECT_KEY) {
    const airbrake = new Notifier({
      projectId: envVars.AIRBRAKE_PROJECT_ID,
      projectKey: envVars.AIRBRAKE_PROJECT_KEY,
      environment: `${envVars.ENVIRONMENT} [CSR]`,
    });
    airbrake.addFilter((notice) => {
      if (
        notice.errors[0].message.includes('Extension context invalidated') || notice.errors[0].backtrace[0]?.file.startsWith('chrome-extension')
      ) {
        // Ignore errors with this messsagefrom chrome extensions
        return null;
      }
      return notice;
    });
    if (envVars.AIRBRAKE_ENABLE == 'true') {
      airbrake.notify(error);
    }
  }
};

const useHandleResponse = () => {
  const [snackbar, setSnackbar] = useLayoutSnackbar();
  const { setError } = useLayoutError();
  const { t } = useTranslation();

  const inPlaceCheck = (inPlace, statusCode = 500, msg) => {
    if (inPlace) {
      setSnackbar({
        ...snackbar,
        open: true,
        severity: 'error',
        message:
          msg
          || t(
            `fe_errors:errors.error_${statusCode != 400 ? statusCode : 500
            }.description`,
          ),
      });
    } else {
      setError({ statusCode });
    }
  };

  const handleError = (err, inPlace = true) => {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      try {
        try {
          let response;
          typeof err.response.data === 'object'
            ? (response = err.response.data)
            // eslint-disable-next-line no-unused-vars
            : (response = JSON.parse(err.response.data));
        } catch (e) {
          inPlaceCheck(inPlace, 500);
          notifyAirbrake({
            reason: 'Not able to parse',
            response: err.response,
          });
        }

        switch (err.response.status) {
          case 401:
            if (inPlace) {
              setSnackbar({
                ...snackbar,
                open: true,
                severity: 'error',
                message: t('fe_errors:errors.error_401'),
              });
            } else {
              router.push(`/users/sign_in?return_url=${window.location.href}`);
            }
            break;
          case 403:
            inPlaceCheck(inPlace, 403, err.response.data?.error_message || err.response.data?.message);
            break;
          case 400:
            notifyAirbrake(err.response);
            inPlaceCheck(inPlace, 500);
            break;
          case 404:
            inPlaceCheck(inPlace, 404);
            break;
          default:
            inPlaceCheck(inPlace, 500);
        }
      } catch (e) {
        notifyAirbrake(err.response);
        inPlaceCheck(inPlace, 500);
      }
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(err.request);
      inPlaceCheck(inPlace, 500);
    } else {
      // Something happened in setting up the request that triggered an Error
      notifyAirbrake(err);
      inPlaceCheck(inPlace, 500);
      // console.log('Error', err.message);
    }
  };

  return { handleError };
};

export default useHandleResponse;
// export default withTranslation("views")(useHandleResponse);
