const crawlerUserAgents = [
  'googlebot',
  'yahoo! slurp',
  'bingbot',
  'yandex',
  'baiduspider',
  'facebookexternalhit',
  'twitterbot',
  'rogerbot',
  'linkedinbot',
  'embedly',
  'quora link preview',
  'showyoubot',
  'outbrain',
  'pinterest/0.',
  'developers.google.com/+/web/snippet',
  'slackbot',
  'vkShare',
  'w3c_validator',
  'redditbot',
  'applebot',
  'WhatsApp',
  'flipboard',
  'tumblr',
  'bitlybot',
  'skypeUriPreview',
  'nuzzel',
  'discordbot',
  'qwantify',
  'pinterestbot',
  'bitrix link preview',
  'xing-contenttabreceiver',
  'chrome-lighthouse',
  'google page speed',
  'ahrefsbot',
  'woorankreview',
];

const isCrawlers = (userAgent) => new RegExp(crawlerUserAgents.join('|')).test(userAgent.toLowerCase());
export default isCrawlers;
