import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'next/image';
import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';
import { withTranslation } from 'config/i18n';
import { useLayoutState } from 'components/shared/Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bgWrap: {
    height: 'calc(100vh - 112px)',
    width: '100vw',
    overflow: 'hidden',
    zIndex: -1,
  },
  bgContent: {
    width: theme.typography.pxToRem(640),
    position: 'absolute',
    bottom: theme.spacing(12),
    margin: 0,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: '3rem',
    textAlign: 'left',
    paddingTop: '40vh',
    '& $statusCode': {
      fontSize: theme.typography.pxToRem(150),
      color: theme.palette.common.white,
    },
    '& $panel': {
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: theme.palette.common.white,
      padding: theme.spacing(4, 2),
      textAlign: 'left',
      '& $header': {
        fontSize: theme.typography.pxToRem(29),
        textTransform: 'uppercase',
        marginBottom: theme.spacing(1.5),
      },
      '& $description': {
        fontSize: theme.typography.pxToRem(14),
        marginBottom: theme.spacing(0.5),
      },
      '& $phones': {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
      },
      '& $btnWrap': {
        textAlign: 'right',
        '& $button': {
          fontSize: theme.typography.pxToRem(18),
          padding: theme.spacing(0.5, 2),
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  },
  statusCode: {},
  panel: {},
  header: {},
  description: {},
  phones: {},
  button: {},
  btnWrap: {},
}));

const Error = ({
  t, statusCode = 500, title, subtitle,
}) => {
  const classes = useStyles();

  const { layoutState } = useLayoutState();

  return (
    <div className={classes.root}>
      <div className={classes.bgWrap}>
        <Image
          alt="error"
          src="/imgs/error.jpg"
          layout="fill"
          objectFit="cover"
          quality={100}
          unoptimized
        />
      </div>
      <Container maxWidth="xl">
        <div className={classes.bgContent}>
          <span className={classes.statusCode}>{statusCode}</span>
          <div className={classes.panel}>
            <Typography className={classes.header}>
              {title || t(`fe_errors:errors.error_${statusCode}.header`)}
            </Typography>
            <Typography className={classes.description}>
              {subtitle
                || t(`fe_errors:errors.error_${statusCode}.description`)}
            </Typography>
            {layoutState?.help_tout
              && layoutState.help_tout.phones
              && Array.isArray(layoutState.help_tout.phones) && (
                <Typography color="primary" className={classes.phones}>
                  {layoutState.help_tout.phones.join(' | ')}
                </Typography>
            )}
            <div className={classes.btnWrap}>
              <Button
                href="/"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {t('fe_errors:errors.home')}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation('fe_errors')(Error);
