import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import axios, { API } from 'config/axios';
import { withTranslation } from 'config/i18n';

import getConfig from 'next/config';
// Material UI Core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// React components
import { useLayoutSnackbar, useLayoutState } from 'components/shared/Layout';
import LightTextField from 'components/shared/LightTextField';
import useErrorHandler from 'utils/useErrorHandler';
// Material UI icons
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '90%',
    maxHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '100vh',
      margin: 0,
      height: '100%',
      borderRadius: 0,
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },
  dialogContent: {
    padding: [0, '!important'],
  },
  container: {
    display: 'flex',
  },
  dialogImg: {
    display: 'flex',
  },
  dialogBody: { width: '100%;' },
  formWrapper: {
    padding: theme.spacing(4, 7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 2.5),
    },
  },
  form: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
    "& > [class*='MuiGrid-container']": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  send: {
    backgroundColor: theme.palette.primary.main,
  },
  success: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(16, 6, 0),
    '& $successIcon': {
      "& [class*='MuiSvgIcon-root']": {
        fontSize: '4rem',
        color: theme.palette.success.main,
      },
    },
    '& $thankyou': {
      color: theme.palette.success.main,
      margin: theme.spacing(1, 1, 2),
    },
    '& $successMsg': {
      color: theme.palette.grey[300],
    },
  },
  successIcon: {},
  thankyou: {},
  successMsg: {},
}));

const initialState = {
  category_id: '',
  lead_type_id: '',
  reservation_number: '',
  comments: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  'g-recaptcha-response': '',
  referring_page: null,
  user_id: null,
};

const CustomTripDialog = (props) => {
  const classes = useStyles();
  const { layoutState } = useLayoutState();

  const {
    publicRuntimeConfig: { envVars },
  } = getConfig();

  const recaptchaRef = React.useRef();

  // Exclude `tReady` to not be passed to Dialog through ...other
  const { onClose, open, t, ...other } = props;

  const [snackbar, setSnackbar] = useLayoutSnackbar();

  const [value, setValue] = React.useState(initialState);

  const [errorMsgs, setErrorMsgs] = React.useState({});

  const [completed, setCompleted] = React.useState(false);

  React.useEffect(() => {
    if (layoutState?.custom_trip) {
      setValue({
        ...value,
        ...(layoutState.current_user ? layoutState.current_user : {}),
        lead_type_id: layoutState.custom_trip.lead_type_id,
        category_id: layoutState.custom_trip.category_id,
      });
    }
  }, [layoutState]);

  const handleInputChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsgs({});
    try {
      const { 'g-recaptcha-response': recaptchaResponse, ...rest } = value;
      await axios.post(API.contactUs, {
        authenticity_token: layoutState.authenticity_token,
        'g-recaptcha-response': recaptchaResponse,
        contact_info: {
          ...rest,
          referring_page: window.location.href,
          user_id: layoutState.current_user ? layoutState.current_user.id : null,
        },
      });
      setCompleted(true);
      setValue(initialState); // Reset dialog
    } catch (error) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      let message = '';
      let isExpected = false;
      if (error.response?.status && error.response.data) {
        const errorData = error.response.data;
        switch (error.response.status) {
          case 406:
            if (errorData.errors) {
              setErrorMsgs({
                ...errorData.errors.contact_info,
              });
              if (errorData.errors.contact_info.base) {
                message = errorData.errors.contact_info.base[0];
              }
            }
            isExpected = true;
            break;
          case 403:
            message = errorData.message;
            isExpected = true;
            break;
          default:
            break;
        }
      }
      if (message) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: 'error',
          message,
        });
      }
      if (!isExpected) {
        useErrorHandler(error, t, [snackbar, setSnackbar]);
      }
    }
  };

  const onRecaptchChange = (token) => {
    setValue({
      ...value,
      'g-recaptcha-response': token,
    });
  };

  const handleClose = () => {
    onClose();
    setCompleted(false);
  };

  const form = (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.first_name)}
            fullWidth
            color="secondary"
            label={t('custom_trip_component:first_name')}
            variant="outlined"
            name="first_name"
            value={value.first_name || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.first_name}
            disabled={Boolean(layoutState.current_user?.first_name)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.last_name)}
            fullWidth
            color="secondary"
            label={t('custom_trip_component:last_name')}
            variant="outlined"
            name="last_name"
            value={value.last_name || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.last_name}
            disabled={Boolean(layoutState.current_user?.last_name)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.email)}
            fullWidth
            color="secondary"
            label={t('custom_trip_component:email')}
            type="email"
            variant="outlined"
            name="email"
            value={value.email || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.email}
            disabled={Boolean(layoutState.current_user?.email)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LightTextField
            required
            error={Boolean(errorMsgs.phone)}
            fullWidth
            color="secondary"
            label={t('custom_trip_component:phone_number')}
            type="tel"
            variant="outlined"
            name="phone"
            value={value.phone || ''}
            onChange={handleInputChange}
            helperText={errorMsgs.phone}
            disabled={Boolean(layoutState.current_user?.phone)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          {envVars.RECAPTCHA_SITE_KEY && (
            <div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={envVars.RECAPTCHA_SITE_KEY}
                onChange={onRecaptchChange}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.send}
          >
            {t('custom_trip_component:get_started')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Dialog
      id="custom-trip-dialog"
      open={open}
      classes={{
        paper: classes.paper,
      }}
      maxWidth="xl"
      keepMounted
      onClose={handleClose}
      {...other}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.container}>
          <Hidden smDown implementation="css">
            <picture>
              <source media="(min-width: 1024px)" srcSet="/imgs/custom-trip.jpg" />
              <source media="(min-width: 600px)" srcSet="/imgs/custom-trip-tablet.jpg" />
              <img className={classes.dialogImg} src="/imgs/custom-trip.jpg" />
            </picture>
          </Hidden>
          <div className={classes.dialogBody}>
            <DialogTitle>
              <Typography variant="h5">
                {t('custom_trip_component:custom_trip_information')}
              </Typography>
              <IconButton onClick={handleClose} aria-label="Cancel" color="secondary">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {!completed ? (
              <div className={classes.formWrapper}>
                <Typography variant="caption">
                  {t('custom_trip_component:custom_trip_description')}
                </Typography>
                {form}
              </div>
            ) : (
              <div className={classes.success}>
                <div className={classes.successIcon}>
                  <CheckCircleIcon />
                </div>
                <Typography variant="h6" className={classes.thankyou}>
                  {t('custom_trip_component:thank_you')}
                </Typography>
                <Typography variant="h6" className={classes.successMsg}>
                  {t('custom_trip_component:one_step_closer')}
                  &nbsp;
                  <b>{t('custom_trip_component:contact_you_soon')}</b>
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CustomTripDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default withTranslation(['custom_trip_component'])(CustomTripDialog);
