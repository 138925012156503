import React from 'react';
import dynamic from 'next/dynamic';
import { withTranslation } from 'config/i18n';
// Material UI Core
import { makeStyles, alpha } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
// Material UI Icons
import Person from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
// React components
import { useLayoutState } from 'components/shared/Layout';
import Link from 'components/shared/Link';
import EsLogoNav from 'components/shared/EsLogoNav';
import JoinUsDrawer from './JoinUsDrawer';
import CountrySelector from './CountrySelector';
import CurrencySelector from './CurrencySelector';
import DarkButton from '../../shared/DarkButton';

const UserDrawer = dynamic(
  () => import('./UserDrawer'),
  { ssr: false },
);
const useStyles = makeStyles((theme) => ({
  root: {

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    "& [class*='MuiAppBar-root']": {
      backgroundColor: theme.palette.grey.A400,
    },
  },
  container: {
    textAlign: 'right',
    '& > *': {
      margin: `0 ${theme.typography.pxToRem(6)}`,
      height: theme.typography.pxToRem(34),
    },
    '& > div': {
      margin: 0,
      height: 'auto',
    },
  },
  signInButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  userButton: {
    height: theme.typography.pxToRem(41),
    '&:not(.square)': {
      borderRadius: '1.1875rem 0.125rem 0.125rem 1.1875rem !important',
      paddingLeft: 0,
      '& $userInfo': {
        margin: '0.1rem 0.75rem 0 0.75rem',
      },
    },
    '& $avatar': {
      width: theme.typography.pxToRem(34),
      height: theme.typography.pxToRem(34),
      boxShadow: `0 0 0 3px ${theme.palette.grey.A400}`,
    },
    '& $userInfo': {
      lineHeight: 1,
      textAlign: 'left',
      maxWidth: `${theme.typography.pxToRem(255)} !important`,
      '& .caption': {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.pxToRem(10),
        textTransform: 'uppercase',
        letterSpacing: theme.typography.pxToRem(0.5),
        fontWeight: 400,
      },
      '& $typography': {
        color: alpha(theme.palette.common.white, 0.8),
        whiteSpace: 'nowrap',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  avatar: {},
  typography: {},
  userInfo: {},
  swapIcon: {
    fontSize: [theme.typography.pxToRem(24), '!important'],
  },
  badge: {
    margin: theme.spacing(0, 0.7, 0, 1.4),
  },
  avatarBadge: {
    "& [class*='MuiBadge-badge']": {
      backgroundColor: theme.palette.error.main,
      border: `2px solid ${theme.palette.grey[600]}`,
    },
  },
}));

const TopBar = ({ t }) => {
  const classes = useStyles();
  const { layoutState } = useLayoutState();

  const isAuthenticated = layoutState?.current_user && (layoutState.current_user.id);

  const [open, setOpen] = React.useState(false); // JoinUs Drawer
  const [openUser, setUserOpen] = React.useState(false); // Logged-in user Drawer

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar>
          <Container fixed className={classes.container}>
            <EsLogoNav />

            <>
              <DarkButton
                variant="outlined"
                component={Link}
                href="/rent-my-motorcycle"
                size="medium"
                style={{ borderColor: '#fff' }}
              >
                {t('fe_es_layout:navigation.rent_my_bike')}
              </DarkButton>
              <DarkButton
                variant="outlined"
                component={Link}
                href="/check-in"
                size="medium"
                style={{ borderColor: '#fff' }}
              >
                {t('layout:navigation.check_in')}
              </DarkButton>
              <CurrencySelector />
              <CountrySelector />
              {!isAuthenticated ? (
                <Button
                  className={classes.signInButton}
                  variant="contained"
                  color="primary"
                  aria-label="open drawer"
                  onClick={() => setOpen(true)}
                  edge="start"
                  size="medium"
                  startIcon={<Person />}
                  disableElevation
                >
                  {`${t('layout:navigation.join_us')} / ${t(
                    'layout:navigation.sign_in',
                  )}`}
                </Button>
              ) : (
                <>

                  {layoutState.settings?.show_current_company
                    && layoutState.current_company
                    && layoutState.current_company.name && (
                      <DarkButton
                        variant="contained"
                        color="secondary"
                        edge="start"
                        size="medium"
                        disableElevation
                        className={`square ${classes.userButton}`}
                      >
                        <div className={`${classes.userInfo} truncate`}>
                          <span className="caption">
                            {t('layout:navigation.company')}
                          </span>
                          <Typography
                            variant="subtitle2"
                            className={classes.typography}
                          >
                            {layoutState.current_company.name}
                          </Typography>
                        </div>
                      </DarkButton>
                  )}
                  <DarkButton
                    variant="contained"
                    color="secondary"
                    onClick={() => setUserOpen(true)}
                    edge="start"
                    size="medium"
                    endIcon={<MenuIcon />}
                    disableElevation
                    className={classes.userButton}
                  >
                    <Badge
                      overlap="circular"
                      className={classes.avatarBadge}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={layoutState.pending_reservations_count}
                    >
                      <Avatar
                        className={classes.avatar}
                        src={
                        layoutState.current_user.avatar_url
                        || '/imgs/default.png'
                      }
                        alt={`${layoutState.current_user.first_name} ${layoutState.current_user.last_name}`}
                      />
                    </Badge>

                    <div className={`${classes.userInfo} truncate`}>
                      <span className="caption">
                        {t('layout:navigation.welcome_msg')}
                      </span>
                      <Typography
                        variant="subtitle2"
                        className={classes.typography}
                      >
                        {`${layoutState.current_user.first_name} ${layoutState.current_user.last_name}`}
                      </Typography>
                    </div>
                  </DarkButton>
                </>
              )}
            </>

          </Container>

        </Toolbar>
      </AppBar>
      {isAuthenticated ? (
        <UserDrawer
          open={openUser}
          setOpen={setUserOpen}
          handleDrawerClose={() => setUserOpen(false)}
          currentUser={layoutState.current_user}
          pendingReservationsCount={layoutState.pending_reservations_count}
        />
      ) : (
        <JoinUsDrawer
          open={open}
          handleDrawerClose={() => setOpen(false)}
        />
      )}

    </div>
  );
};

export default React.memo(withTranslation(['layout', 'fe_es_layout'])(TopBar));
