/** *
 * Custom hook error handler
 ** */

const useErrorHandler = (error, t, [snackbar, setSnackbar]) => {
  

  let message = t ? t('controllers:messages.Something_went_wrong!') : '';

  if (error.response?.status && error.response.data) {
    if (error.response.data.error_message || error.response.data.message) {
      message = error.response.data.error_message || error.response.data.message;
    }
  }

  if (snackbar && setSnackbar && message) {
    setSnackbar({
      ...snackbar,
      open: true,
      severity: 'error',
      message,
    });
  }

  return {};
};

export default useErrorHandler;
