import React from 'react';
import dynamic from 'next/dynamic';
// Material UI core
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
// Material UI icons
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
// React components
import { useLayoutState } from 'components/shared/Layout';
import useSiteSlug from 'common/hooks/useSiteSlug';
import JoinUsDrawer from '../TopBar/JoinUsDrawer';
import MobileNavigationDrawer from './MobileNavigationDrawer';

const UserDrawer = dynamic(() => import('../TopBar/UserDrawer'), { ssr: false });

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  appBar: {
    // backgroundColor: theme.palette.primary.dark,
    color: [theme.palette.common.white, '!important'],
  },
  toolbar: {
    justifyContent: 'space-between',
    paddingLeft: '1rem',
  },
  homeLink: {
    fontSize: '1.1875rem',
    fontFamily: theme.typography.fontFamilySecondary,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  esLogo: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(20),
  },
  menuIconLogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MenuBar() {
  const classes = useStyles();

  const { layoutState } = useLayoutState();
  const { structureURL } = useSiteSlug();

  const isAuthenticated = layoutState?.current_user && layoutState.current_user.id;

  const [mobileOpen, setMobileOpen] = React.useState(false); // Mobile Navigation Drawer
  const [open, setOpen] = React.useState(false); // JoinUs Drawer

  const handleMobileDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="secondary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp implementation="css" className={classes.menuIconLogoContainer}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.homeLink}
              component={Link}
              href={structureURL(null, layoutState?.current_language?.code)}
            >
              <Typography classes={{ root: classes.esLogo }}>
                eagle
                <b>share</b>
              </Typography>
            </Typography>
            <MobileNavigationDrawer
              mobileOpen={mobileOpen}
              handleDrawerClose={handleMobileDrawerClose}
              handleDrawerOpen={handleMobileDrawerOpen}
            />
          </Hidden>
          <Hidden lgUp implementation="css">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerOpen}
              color="inherit"
            >
              <PersonIcon />
            </IconButton>
            {isAuthenticated ? (
              <>
                <UserDrawer
                  open={open}
                  setOpen={setOpen}
                  handleDrawerClose={handleDrawerClose}
                  currentUser={layoutState.current_user}
                  pendingReservationsCount={layoutState.pending_reservations_count}
                  settings={layoutState.settings}
                  currentCompany={layoutState.current_company}
                />
              </>
            ) : (
              <JoinUsDrawer open={open} handleDrawerClose={handleDrawerClose} />
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default React.memo(MenuBar);
